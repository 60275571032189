$maxZIndex: 2147483647;

@font-face {
  font-family: 'Glyphicons Regular';
  src: url('./fonts/glyphicons-regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/lato-regular.woff') format('woff');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  position: relative;
  min-height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #dedede;
  padding-top: 4.4rem;
  font-family: Lato, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  margin: 0 0 40px;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .browser-upgrade-btns {
    position: absolute;
    z-index: $maxZIndex;
    top: 300px;
    &.browser-upgrade-on {
      left: 20px;
    }
    &.browser-upgrade-off {
      left: 60px;
    }
  }

  .fade-out {
    will-change: visibility, opacity;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }

  .fade-in {
    will-change: opacity;
    opacity: 1;
    transition: opacity 1s linear;
  }

  .hidden {
    display: none !important;
  }

  .visible {
    display: block !important;
  }

  h1 {
    margin: 0 auto 32px auto;
    text-indent: -9999em;
    background: 0 0 no-repeat url('./images/tillr.svg');
    width: 120px;
    height: 53px;
  }

  &.registration {
    h1 {
      margin-top: 0;
    }
  }

  &.static {
    h1 {
      opacity: 1;
    }
  }

  #tillr-loading {
    z-index: $maxZIndex;
    background-color: #dedede;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .tillr-loading {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .tillr-loading div {
      position: absolute;
      top: 0;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #fd7300;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .tillr-loading div:nth-child(1) {
      left: 8px;
      animation: tl-shape-1 0.6s infinite;
    }
    .tillr-loading div:nth-child(2) {
      left: 8px;
      animation: tl-shape-2 0.6s infinite;
    }
    .tillr-loading div:nth-child(3) {
      left: 32px;
      animation: tl-shape-2 0.6s infinite;
    }
    .tillr-loading div:nth-child(4) {
      left: 56px;
      animation: tl-shape-3 0.6s infinite;
    }
  }

  section {
    margin: 32px auto 0;
    max-width: 448px;
    padding: 32px;
    position: relative;
    background-color: #fff;
    border-radius: 5px;

    &.dialog__wrapper {
      margin: 0;
      padding: 172px 0 0 0;
      max-width: none;
      border: none;
      z-index: $maxZIndex - 1;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.7);
      overflow: hidden;
    }

    p {
      font-size: 1.6rem;
    }

    fieldset {
      padding: 0;
      margin: 0;
      border: 0;

      .form-group {
        margin-bottom: 15px;

        &.error {
          label {
            color: #a94442;
          }
          input {
            border-color: #a94442;
          }
        }
      }

      label {
        font-size: 1.6rem;
        padding-bottom: 2px;
        line-height: 1.8rem;
        margin-bottom: 5px;
        display: inline-block;
        font-weight: 400;
      }

      input[type='text'],
      input[type='number'],
      input[type='email'],
      input[type='password'] {
        font-size: 1.6rem;
        height: auto;
        padding: 12px;
        border-radius: 3px;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        line-height: 1.42857;
        display: block;
        width: 100%;
        min-height: 50px;
      }

      input[type='checkbox'] {
        position: absolute;
        margin: 4px 0 0;
        margin-left: -20px;
        line-height: normal;
        padding: 0;
      }

      .checkbox {
        position: relative;
        margin-top: 10px;
        margin-bottom: 20px;

        > label {
          padding-left: 0;
        }

        label {
          min-height: 20px;
          margin-bottom: 0;
          cursor: pointer;
        }
      }

      .input-group {
        position: relative;
        display: table;
        border-collapse: separate;

        input {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          display: table-cell;
          position: relative;
          z-index: 2;
          float: left;
          width: 100%;
          margin-bottom: 0;
        }

        div {
          outline: none;
          padding: 6px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          color: #555;
          text-align: center;
          background-color: #eee;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 1%;
          white-space: nowrap;
          vertical-align: middle;
          display: table-cell;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          border-left: 0;
          cursor: pointer;

          i {
            display: block;
            width: 30px;
            height: 30px;
            background-size: auto 30px;
          }
        }
      }
    }

    button {
      font-size: 1.8rem;
      text-transform: uppercase;
      color: #fff;
      border-radius: 2px;
      background-color: #333;
      outline: 0 !important;
      display: inline-block;
      margin-bottom: 0;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      white-space: nowrap;
      padding: 6px 18px;
      line-height: 1.42857;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: inherit;
      width: 100%;

      &[disabled],
      &[disabled='disabled'] {
        cursor: not-allowed;
        opacity: 0.65;
        filter: alpha(opacity=65);
        box-shadow: none;
      }

      &:hover {
        background-color: #666;
      }
    }

    p.help {
      color: #999;
      font-size: 1.4rem;
    }

    div.message {
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 1px;
      padding: 15px;
      margin: 0 0 20px;
      border: 1px solid #faebcc;
      background-color: #fcf8e3;

      &.error {
        background-color: #f2dede;
        border-color: #ebccd1;
      }

      p {
        margin: 0;
      }
    }

    .progress {
      height: 4px;
      width: 100%;
      overflow: hidden;
      background-color: #d6b599;
      position: absolute;
      top: 0;
      left: 0;
      display: none;

      &:before {
        display: block;
        position: absolute;
        content: '';
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: #fd7300;
        animation: loading 2s linear infinite;
      }
    }
  }

  &.registration {
    section {
      margin-bottom: calc(4.4rem + 64px);

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  form.pending .progress {
    display: block;
  }

  div.page-loading {
    opacity: 0;
  }

  footer {
    background-color: #333;
    position: absolute;
    height: 40px;
    bottom: 0;
    width: 100%;
    opacity: 0;

    p {
      line-height: 40px;
      font-size: 1.1rem;
      text-align: center;
      margin: 0;
      color: #fff;
    }
  }

  .dialog {
    margin: 0 auto;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    min-width: 240px;
    max-width: 280px;
    overflow: auto;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14),
      0 5px 24px 4px rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 16777271;
    text-align: left;

    .dialog__content {
      padding: 20px 24px 0 24px;

      h3 {
        font-size: 2rem;
        font-weight: bold;
        letter-spacing: 0.005em;
        margin: 0 0 1.4rem 0;
        line-height: 1.2em;
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.01em;
        line-height: 2.4rem;
        margin: 0 0 1.5rem;
        color: #797979;
      }

      &--no-controls {
        padding-bottom: 20px;
      }
    }

    .dialog__controls {
      padding-right: 8px;
      padding-left: 16px;
      min-height: 53px;
      overflow: hidden;
      text-align: right;

      button {
        background-color: #fff;
        color: #00a0a5;
        margin: 8px 0 8px 8px;
        outline: none;
        display: inline-block;
        cursor: pointer;
        min-height: 36px;
        min-width: 60px;
        line-height: 36px;
        vertical-align: middle;
        text-align: center;
        border: 0;
        padding: 0 6px;
        white-space: nowrap;
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        width: auto;
      }
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 20px;
    text-align: left;
  }

  div.heading {
    h2,
    p {
      margin: 0 0 14px;
    }
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      background-color: #f7f7f7;
      border-bottom: 1px solid #d5d5d5;
      border-radius: 2px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
      margin: 0 auto;

      button {
        background-color: #f7f7f7;
        border: 0;
        cursor: pointer;
        display: block;
        outline: none;
        padding: 14px 50px 16px 20px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #111;
        position: relative;
        width: 100%;
        font-weight: bold;

        i {
          display: block;
          width: 30px;
          height: 30px;
          background-image: url('./images/chevron.svg');
          background-size: auto 30px;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
    }
  }

  &.brand-pdsc {
    h1 {
      background: 0 0 no-repeat url('./images/pdsc.svg');
      width: 151px;
    }

    section {
      button {
        background-color: #093c71;
        border-color: #093c71;
      }

      .progress {
        background-color: #93d308;
        &:before {
          background-color: #093c71;
        }
      }
    }

    .magic-checkbox:checked + label:before {
      border-color: #093c71;
      background: #093c71;
    }

    .magic-checkbox + label:hover:before {
      animation-name: pdsc-hover-color;
    }

    .dialog__controls {
      button {
        color: #093c71;
      }
    }
  }
}

@media not print {
  .magic-checkbox {
    position: absolute;
    display: none;
  }

  .magic-checkbox + label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    top: -1px;
    cursor: pointer;
    vertical-align: middle;
  }

  .magic-checkbox + label:hover:before {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: hover-color;
  }

  .magic-checkbox + label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    content: '';
    border: 1px solid #c0c0c0;
  }

  .magic-checkbox + label:after {
    position: absolute;
    display: none;
    content: '';
  }

  .magic-checkbox:checked + label:before {
    animation-name: none;
  }

  .magic-checkbox:checked + label:after {
    display: block;
  }

  .magic-checkbox + label:before {
    border-radius: 3px;
  }

  .magic-checkbox + label:after {
    top: 2px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
  }

  .magic-checkbox:checked + label:before {
    border-color: #333;
    background: #333;
  }
}

@-webkit-keyframes hover-color {
  from {
    border-color: #c0c0c0;
  }
  to {
    border-color: #00a0a5;
  }
}

@keyframes hover-color {
  from {
    border-color: #c0c0c0;
  }
  to {
    border-color: #00a0a5;
  }
}

@-webkit-keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@keyframes tl-shape-1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes tl-shape-3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes tl-shape-2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

body.outdated-browser--show {
  .outdated-browser {
    animation: 1s ease-out 0s outdatedBrowser;
    animation-fill-mode: forwards;
  }
}

.outdated-browser {
  position: fixed;
  z-index: 111111;
  width: 100%;
  top: 0px;
  left: 0px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #f25648;
  font-size: 1.7rem;
  font-family: Lato, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  color: #fff;
  opacity: 0;
  transform: translateY(-1000px);
  will-change: opacity, transform;
}

.outdated-browser__head {
  text-align: justify;
  padding: 16px 32px 16px 16px;
}

.outdated-browser__head p {
  margin: 0;
  padding: 0;
}

.outdated-browser__foot {
  padding: 0 16px 16px 16px;
  color: #fff;
  font-family: Lato, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial,
    Lucida Grande, sans-serif;
  text-align: center;
}

.outdated-browser__foot a {
  display: inline-block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border: solid 1px #fff;
  background-color: #f25648;
  text-transform: uppercase;
  line-height: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.15rem;
  margin: 0 0 8px 0;
  font-size: 1.4rem;
}

.outdated-browser__foot a:hover {
  text-decoration: none;
  background-color: #fff;
  color: #f25648;
}

@keyframes outdatedBrowser {
  from {
    opacity: 0;
    transform: translateY(-200px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 700px) {
  .outdated-browser__head {
    padding: 16px 8px 16px 8px;
  }
}
