button.page-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #111;
  cursor: pointer;
  font-size: 1.6rem;

  /* Reset button styles */
  text-align: left;
  text-transform: none;
}
